import React from 'react'
import { Clerk } from '@formclerk/react'

import Layout from 'components/Layout'
import SiteMetadata from 'queries/SiteMetadata'

const Rent = () => {
  const { clerkId } = SiteMetadata()

  return (
    <Layout pageTitle='Home'>
      <oma-grid-row>
        <section className='section page__column'>
          <h2 className='article__heading'>Uthyrning</h2>
          <p>
            Vi hyr ut trädgårdsredskap, bilar, släp och skåpbil. Hör gärna av er
            med en bokningsförfrågan så återkommer vi så snart vi kan. Tänk på
            att vara ute i god tid med din bokningsförfrågan.
          </p>
          <Clerk
            className='form'
            contentClassName='form__content'
            clerkID={`${clerkId}/rental`}
            Processing={() => (
              <p>Skickar kontaktinformation, vänligen vänta...</p>
            )}
            Success={() => (
              <p>Tack för bokningen! Vi hör av oss så snart vi kan.</p>
            )}
            Failure={() => (
              <p>
                Något gick fel med bokningen. Du får gärna kontakta oss på annat
                vis.
              </p>
            )}
            Initializing={() => <p>Laddar formuläret...</p>}
          >
            <label className='form__label'>
              Vad vill du hyra? *
              <select
                name='typ-av-förfrågan'
                className='form__input'
                required={true}
              >
                <option value='trädgårdsredskap'>Trädgårdsredskap</option>
                <option value='bil'>Bil</option>
                <option value='släp'>Släpvagn</option>
                <option value='skåpbil'>Skåpbil</option>
              </select>
            </label>
            <label className='form__label'>
              Starttid *
              <input
                type='datetime-local'
                name='starttid'
                required={true}
                className='form__input'
              />
            </label>
            <label className='form__label'>
              Sluttid *
              <input
                type='datetime-local'
                name='sluttid'
                required={true}
                className='form__input'
              />
            </label>
            <label className='form__label'>
              Namn *
              <input
                type='text'
                name='namn'
                className='form__input'
                required={true}
              />
            </label>
            <label className='form__label'>
              E-post eller telefon *
              <input
                type='text'
                name='kontakt'
                className='form__input'
                required={true}
              />
            </label>
            <label className='form__label'>
              Meddelande
              <textarea rows='7' name='meddelande' className='form__input' />
            </label>
            <input type='submit' value='Skicka' className='form__submit' />
          </Clerk>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default Rent
